import 'regenerator-runtime/runtime';
import 'core-js';
import React from 'react';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import ApiClient from './helpers/ApiClient';
import configureStoreMain from './redux/create';
import {
  hubSpot,
  hotjarScript,
  appCues,
} from './scripts';
import config from './config';
import RootContainer from './containers/RootContainer';
import versionObserver from './utils/versionObserver';
import { showMessage } from './helpers/uiHelper';
import '../theme/assets/fonts.scss';
import '../theme/img/clevergig_logo_cmyk_element.png';
import './utils/polyfills';

// Register hubSpot, serverWorkers on stg and live
// -----------------------------------------------------------------------------
if (config.isProduction) {
  hubSpot();
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js').then(() => {
        console.log('SW registered');
      }).catch((registrationError) => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }
}

//
// Register, googleTM, hotjar on live
// -----------------------------------------------------------------------------
if (config.isLive) {
  hotjarScript();

  if (!config.isLogisticForce && !config.isTerborg) {
    appCues();
  }
}

if (config.isLive || config.isStage) {
  Sentry.init({
    dsn: config.sentryDNS,
    ignoreErrors: [
      "SyntaxError: expected expression, got '<'",
      "SyntaxError: Unexpected token '<'",
      'Loading CSS chunk',
      'ChunkLoadError',
      'Failed to fetch',
      'TypeError: Illegal invocation',
      'Request has been terminated',
      'Non-Error exception captured with key',
      'Non-Error promise rejection captured',
    ],
    denyUrls: [
      /^chrome-extension:\/\//i,
    ],
  });
}

if (config.isLive || config.isStage) {
  const FIVE_MINUTES = 5 * 60000;
  const observer = versionObserver({
    target: `${window.location.origin}/version.txt`,
    delay: FIVE_MINUTES,
    onUpdate: () => {
      showMessage('The new version of Clevergig is available. Reloading...');

      setTimeout(() => {
        window.location.reload(true);
      }, 4000);
    },
    onReject: (error) => {
      window.console.error('Version has not been fetched', error);
    }
  });

  const handleFocus = () => {
    observer.turnOn();
  };

  const handleBlur = () => {
    observer.turnOff();
  };

  observer.start();
  observer.turnOn();

  window.addEventListener('load', () => {
    window.addEventListener('blur', handleBlur);
    window.addEventListener('focus', handleFocus);
  });

  window.addEventListener('unload', () => {
    window.removeEventListener('blur', handleBlur);
    window.removeEventListener('focus', handleFocus);

    observer.stop();
  });
}

// tracking performance and rendering
// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   whyDidYouRender(React, {
//     trackAllPureComponents: true,
//   });
// }

const rootEl = document.getElementById('root');
const client = new ApiClient();
const store = configureStoreMain(client);

// required for webpack-hot-middleware
if (module.hot) {
  module.hot.accept();
}

const root = createRoot(rootEl);
root.render(<RootContainer store={store} />);
